import React, { Component } from 'react';
import { graphql, StaticQuery, Link } from 'gatsby';

class OtherRooms extends Component {

    render() {
        return <StaticQuery query={graphql`
        {
            wordpressPage(slug: { eq: "rooms-rates" }) {
                acf {
                    other_rooms_title
                    more_info_button
                    book_now_button
                }
            }
            allWordpressWpRooms {
                edges {
                    node {
                        id
                        title
                        slug
                        featured_media {
                            source_url
                        }
                        acf {
                            price_info_1___title
                            price_info_1___subtitle
                            price_info_1___price
                            price_info_2___title
                            price_info_2___subtitle
                            price_info_2___price
                            price_info_3___title
                            price_info_3___subtitle
                            price_info_3___price
                        }
                    }
                }
            }
        }
        `} render={props => (
            <div className="frame">
                <div className="other-rooms">
                    <div className="other-title">
                        { props.wordpressPage.acf.other_rooms_title }
                    </div>
                    <div className="rooms-section">
                        {
                            props.allWordpressWpRooms.edges.map(item => (
                                (this.props.slug !== item.node.slug) ? (
                                <div className="item-wrapper" key={item.node.id}>
                                    <div className="item" >
                                        <div className="title">{item.node.title}</div>
                                        <div className="image">
                                            <img src={item.node.featured_media.source_url} />
                                        </div>
                                        <div className="price-info">
                                            <div className="price-info-details">
                                                <div className="price-title">{item.node.acf.price_info_1___title}</div>
                                                <div className="subtitle">{item.node.acf.price_info_1___subtitle}</div>
                                                <div className="price">{item.node.acf.price_info_1___price}</div>
                                            </div>
                                            <hr />
                                            <div className="price-info-details">
                                                <div className="price-title">{item.node.acf.price_info_2___title}</div>
                                                <div className="subtitle">{item.node.acf.price_info_2___subtitle}</div>
                                                <div className="price">{item.node.acf.price_info_2___price}</div>
                                            </div>
                                            <hr />
                                            <div className="price-info-details">
                                                <div className="price-title">{item.node.acf.price_info_3___title}</div>
                                                <div className="subtitle">{item.node.acf.price_info_3___subtitle}</div>
                                                <div className="price">{item.node.acf.price_info_3___price}</div>
                                            </div>
                                        </div>
                                        <div className="btn-info">
                                            <div className="more-info"><Link to={ `/rooms-rates/${item.node.slug}` }>{ props.wordpressPage.acf.more_info_button }</Link></div>
                                            <div className="book-now"><a href="#">{ props.wordpressPage.acf.book_now_button }</a></div>
                                        </div>
                                    </div>
                                </div> ) : null  
                            ))
                        }
                    </div>
                </div>
            </div>
        ) } />
    }
};

export default OtherRooms