import React from 'react'
import DefaultTemplate from "../templates/default"
import Banner from "../components/room-single/banner"
import Content from "../components/room-single/content"
import OtherRooms from "../components/room-single/other-rooms"

export default ({pageContext}) => (
    <DefaultTemplate>

        <Banner image = { pageContext.acf.banner_image.source_url } title = { pageContext.title }  />

        <Content data = { pageContext } />

        <OtherRooms slug = { pageContext.slug } />
        
    </DefaultTemplate>
);
   