import React, { Component } from 'react';
import { graphql, StaticQuery } from 'gatsby';

class Banner extends Component {
    
    render() { 
        
        return <StaticQuery query={graphql`
        {
            wordpressPage(title: { eq: "Home" }){
                acf {
                    check_in_title
                    check_out_title
                    button_title
                }
            }
        }
        `} render={props => (
            <div className="room-single-banner banner">
                <div className="background-banner-type-2" style={{backgroundImage: 'url(' + this.props.image + ')'}}>
                    <div className="banner-bottom">
                        <div className="search-and-tripadvisor">
                            <div className="title">
                                { this.props.title }
                            </div>
                            <div className="search-form">
                                <div className="check-in">
                                    <i className="material-icons">date_range</i>
                                    <input type="text" id="dt1" placeholder={ props.wordpressPage.acf.check_in_title } />
                                </div>
                                <div className="check-out">
                                    <i className="material-icons">date_range</i>
                                    <input type="text" id="dt2" placeholder={ props.wordpressPage.acf.check_out_title } />
                                </div>
                                <div className="search">
                                <button>{ props.wordpressPage.acf.button_title }</button>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        ) } />
    }
};

export default Banner