import React, { Component } from 'react';
import { graphql, StaticQuery } from 'gatsby';

class Content extends Component {

    render() {
        return <StaticQuery query={graphql`
        {
            wordpressPage(title: { eq: "Home" }) {
                acf {
                    top_background_image {
                        source_url
                    }
                }
            }
        }
        `} render={props => (
            <div className="frame">
                <div className="room-single-content">
                    <div className="top-background-image">
                        <img src={props.wordpressPage.acf.top_background_image.source_url} alt="background-image" />
                    </div>
                    <div className="price-info">
                        <div className="price-info-details">
                            <div className="price-title">{this.props.data.acf.price_info_1___title}</div>
                            <div className="subtitle">{this.props.data.acf.price_info_1___subtitle}</div>
                            <div className="price">{this.props.data.acf.price_info_1___price}</div>
                        </div>
                        <div className="price-info-details">
                            <div className="price-title">{this.props.data.acf.price_info_2___title}</div>
                            <div className="subtitle">{this.props.data.acf.price_info_2___subtitle}</div>
                            <div className="price">{this.props.data.acf.price_info_2___price}</div>
                        </div>
                        <div className="price-info-details">
                            <div className="price-title">{this.props.data.acf.price_info_3___title}</div>
                            <div className="subtitle">{this.props.data.acf.price_info_3___subtitle}</div>
                            <div className="price">{this.props.data.acf.price_info_3___price}</div>
                        </div>
                    </div>
                    <div className="superior-section">
                        <div className="title">{this.props.data.acf.superior_title}</div>
                        <div className="content" dangerouslySetInnerHTML = {{ __html: this.props.data.acf.superior_title_content}}></div>
                    </div>
                    {/* <div className="content-section" dangerouslySetInnerHTML = {{ __html: this.props.data.acf.content}}></div>
                    <div className="cancellation-section">
                        <div className="title">{this.props.data.acf.cancellation_fee_title}</div>
                        <div className="content" dangerouslySetInnerHTML = {{ __html: this.props.data.acf.cancellation_fee_content}}></div>
                    </div> */}
                </div>
            </div>
        ) } />
    }
};

export default Content